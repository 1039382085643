<template>
    <div class="plan">
        <!-- 测评计划 -->
        <div class="searchBtn">
            <el-select v-model="searchInfo.status" placeholder="请选择是否启用" size="small">
                <el-option label="全部" :value="3"></el-option>
                <el-option label="否" :value="0"></el-option>
                <el-option label="是" :value="1"></el-option>
                <!-- <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option> -->
            </el-select>
            <el-select v-model="searchInfo.progress" placeholder="请选择进展" size="small">
                <el-option label="全部" :value="0"></el-option>
                <el-option label="未开始" :value="1"></el-option>
                <el-option label="进行中" :value="2"></el-option>
                <el-option label="已结束" :value="3"></el-option>
            </el-select>
            <el-input placeholder="请输入计划名称" v-model="searchInfo.name" clearable size="small">
            </el-input>
            <el-button type="primary" size="small" @click="search">搜索</el-button>
            <el-button type="primary" size="small" @click="AddEvaluationPlan">新增</el-button>
            <el-button type="primary" size="small" @click="deletePlans">删除</el-button>
        </div>
        <div class="tableContent">
            <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection"></el-table-column>
                <el-table-column label="序号" width="100" fixed>
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.number" size="small" @blur="changeOrder(scope.row)">
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="编号" width="100" fixed>
                </el-table-column>
                <el-table-column prop="name" label="测评计划名称" width="250" :show-overflow-tooltip="true" fixed>
                </el-table-column>
                <el-table-column prop="gaugeCount" label="量表数量" width="120">
                </el-table-column>
                <el-table-column prop="startTime" label="开始时间" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.startTime">{{ scope.row.startTime.slice(0, 10) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="endTime" label="结束时间" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.endTime">{{ scope.row.endTime.slice(0, 10) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="progress" label="进展" width="100">
                    <template slot-scope="scope">
                        <template v-if="scope.row.status === 0">
                            <span>未启用</span>
                           
                        </template>
                        <template v-else>
                            <span v-show="scope.row.progress === '1'"> 未开始</span>
                            <span v-show="scope.row.progress === '2'"> 进行中</span>
                            <span v-show="scope.row.progress === '3'">已结束</span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="userCount" label="测评总人数" width="100">
                </el-table-column>
                <el-table-column prop="completedUserCount" label="已完成人数" width="100">
                </el-table-column>
                <el-table-column prop="repeatPlan" label="重复计划" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.repeatPlan === 1">
                            无周期
                        </span>
                        <span v-else-if="scope.row.repeatPlan === 2">
                            每周一次
                        </span>
                        <span v-else-if="scope.row.repeatPlan === 3">
                            每月一次
                        </span>
                        <span v-else-if="scope.row.repeatPlan === 4">
                            每三个月一次
                        </span>
                        <span v-else-if="scope.row.repeatPlan === 5">
                            每六个月一次
                        </span>
                        <span v-else-if="scope.row.repeatPlan === 6">
                            每一年一次
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="nickname" label="创建人" width="120">
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="160">
                </el-table-column>
                <el-table-column label="启用">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status" :active-value="1" @change="changeStatus(scope.row)"
                            :inactive-value="0">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="toEdit(scope.row.id)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="block">
            <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
                layout="total, prev, pager, next, jumper" :total="paging.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getPlanList, updatePlanEvaluation, deletePlan, updateOrder } from '@/api/evaluationManagement/plan'

export default {
    components: {  },
    data() {
        return {
            searchInfo: {
                name: null,
                progress: null,
                status: null
            },
            deleteIdList: [],
            paging: {
                p: 1,
                ps: 19,
                total: 0
            },
            tableData: []
        }
    },
    methods: {
        // 点击新增
        AddEvaluationPlan() {
            // 如果用户点击新增将本地存储的planId,设置为null，用于跳转后判读是新增还是编辑
            sessionStorage.setItem('planId', null);
            this.$router.push('/home/addPlan/PlanOverview')
        },
        // 点击编辑
        toEdit(val) {
            // 如果用户是点击编辑，那么直接将本地存储的planId设置为当前要修改的id
            sessionStorage.setItem('planId', val);
            this.$router.push('/home/addPlan/PlanOverview')
        },
        // 获取测评列表
        async getList() {
            console.log(this.searchInfo);
            let info = JSON.parse(JSON.stringify(this.searchInfo));
            // 3为全部
            if (info.status === 3) {
                info.status = null;
            }
            // progress为0时，是搜索全部
            if (info.progress === 0) {
                info.progress = null;
            }
            const res = await getPlanList({ p: this.paging.p, ps: this.paging.ps, ...info });
            console.log(res);
            if (res.code === 200) {
                this.tableData = res.data.records;
                this.paging.total = res.data.total;
            }
        },
        // 切换分页
        handleCurrentChange(val) {
            this.paging.p = val;
            this.getList();
        },
        // 是否启用
        async changeStatus(val) {
            console.log(val)
            // let info = val;
            // info.createTime = info.createTime + '00:00:00';
            const res = await updatePlanEvaluation({
                id: val.id, status: val.status, startTime: val.startTime, endTime: val.endTime, repeatEndTime: val.endTime,
                repeatStartTime: val.startTime
            });
            console.log(res);
            if (res.code === 200) {
                this.$message({
                    showClose: true,
                    message: '操作成功',
                    type: 'success'
                });
                this.getList();
            } else {
                this.$message({
                    showClose: true,
                    message: '修改失败',
                    type: 'error'
                });
            }

        },
        // 点击搜索
        search() {
            this.paging.p = 1;
            this.getList();
        },
        // 点击选择测评计划
        handleSelectionChange(val) {
            console.log(val)
            this.deleteIdList = [];
            if (val) {
                val.forEach(item => {
                    this.deleteIdList.push(item.id)
                })
            }
        },
        // 删除测评计划
        async deletePlans() {
            console.log(this.deleteIdList);
            if (this.deleteIdList.length > 0) {
                this.$confirm('确认删除该测评计划?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const res = await deletePlan(JSON.stringify(this.deleteIdList));
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            showClose: true,
                            message: '删除成功!'
                        });
                        this.getList();
                    } else {
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: '删除失败!' + res.msg
                        });
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    showClose: true,
                    message: '请选择需要删除的测评计划！'
                });
            }

        },
        // 修改计划序号
        async changeOrder(val) {
            const res = await updateOrder(val.id, val.number);
            console.log(res);
            if (res.code === 200) {
                this.$message({
                    type: 'success',
                    showClose: true,
                    message: '修改成功!'
                });
                this.getList();
            } else {
                this.$message({
                    type: 'error',
                    showClose: true,
                    message: '修改失败!' + res.msg
                });
                this.getList();
            }

        }
    },
    created() {
        this.getList();
    }
}
</script>

<style lang="scss" scoped>
.plan {
    padding: 20px;

    // .searchBtn {

    //     .el-input,
    //     .el-select {
    //         width: 150px;
    //         margin-right: 30px;
    //     }

    //     .el-button {
    //         margin-left: 40px;
    //     }
    // }

    .tableContent {
        margin-top: 20px;
    }

    .block {
        padding: 10px 0px;
        display: flex;
        justify-content: center;
    }
}
</style>